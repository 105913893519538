import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { AssemblerUrl } from '@/lib/UtilsApi';
import API from '@/config/api-routers';
import { makeGetRequest } from '@/api/requests';

export const getOrderList = async (
  pageCurrent: number,
  pageAmountItems: string,
  sort: string,
  filter: string
): Promise<TableApiInterface> => {
  const assemblerUrl = new AssemblerUrl(API.orders.getList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};
