import { computed, reactive, ref } from 'vue';
import moment from 'moment';
import UserModule from '@/store/user';
import { FilterPreview } from '@/store/filter';
import { getClients } from '@/api/client';
import { getRegionsAll } from '@/api/region';
import { getAllPartnersList } from '@/api/partners';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';
import { resetFilter } from '@/lib/util/tableUtils';
import { regionEntity } from '@/interfaces/regionResponse.interface';
import { ServiceTypeMin } from '@/interfaces/models/serviceType.interface';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';
import { CustomerResponseModel } from '@/interfaces/models/customer.interface';
import { getServiceTypeAll } from '@/api/templates';

export function useFilters(changePage: (page: number) => void) {
  const filters = reactive({
    periodStart: moment().format('YYYY-MM-DD'),
    periodEnd: moment().add(moment.duration(14, 'days')).format('YYYY-MM-DD'),
    clients: [] as number[],
    fullMarketName: '',
    serviceTypeId: [],
    regions: [],
    onlyFree: 0,
    partnerUuid: null as null | string,
  });

  const filterSettings: FilterSettings = reactive({
    name: '',
    getListAction: '',
    pageCurrent: 1,
    sort: {},
    filter: '',
    filterAdditional: '',
    filterPreview: {} as Record<string, FilterPreview>,
    filterModel: {},
    filterValues: {},
    filterHandlers: {
      update: '',
      reset: '',
      resetState: '',
      removeSelected: '',
    },
    templateClass: '',
    btnClass: '',
  });

  const isInit = ref(false);

  const filterData = {
    clients: [] as CustomerResponseModel[],
    serviceTypes: [] as ServiceTypeMin[],
    regions: [] as regionEntity[],
    partners: [] as PartnerSelectListItem[],
  };

  const datesData = computed(() => {
    const startValue = moment(filters.periodStart, 'YYYY-MM-DD', true).format('DD/MM/YY');
    const endValue = moment(filters.periodEnd, 'YYYY-MM-DD', true).format('DD/MM/YY');

    return {
      start: {
        title: 'Дата, с',
        value: startValue,
        max: endValue,
      },
      end: {
        title: 'Дата, по',
        value: endValue,
        min: startValue,
      },
    };
  });

  const isSupervisor = computed(() => UserModule.isSupervisor);

  async function initFilterData() {
    isInit.value = false;

    filterData.serviceTypes = await getServiceTypeAll();
    filterData.clients = (await getClients()).clients;
    filterData.regions = await getRegionsAll();

    if (isSupervisor.value) {
      filterData.partners = await getAllPartnersList();
    }

    isInit.value = true;
  }

  function selectDate(val: { day: string; month: string; year: string }, key: 'periodStart' | 'periodEnd'): void {
    filters[key] = `20${val.year}-${val.month}-${val.day}`;
    changePage(1);
  }

  function resetFilters(): void {
    filters.clients = [];
    filters.fullMarketName = '';
    filters.serviceTypeId = [];
    filters.regions = [];
    filters.partnerUuid = null;
    filters.onlyFree = 0;

    changePage(1);
  }

  function removePreviewTag(key: string): void {
    resetFilter(filters, key);
    changePage(1);
  }

  return {
    isInit,
    filters,
    filterSettings,
    filterData,
    datesData,
    selectDate,
    initFilterData,
    resetFilters,
    removePreviewTag,
  };
}
